import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { BrandHeader } from "../components/common/header";
import Content from "../components/sections/datenschutz/content";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "gatsby";

import Logo from "../images/logo_fyzio.svg";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "bg_header_datenschutz.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);
  return (
    <Layout nav={false}>
      <Seo />
      <BrandHeader parallax backgroundImage={data.backgroundImage}>
        <Link to="/" activeClassName="active">
          <img src={Logo} className="z-20" alt="FYZIO – THERAPIE@HOME" />
        </Link>
      </BrandHeader>
      <Content />
    </Layout>
  );
};

export default IndexPage;

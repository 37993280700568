import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Parallax } from "react-scroll-parallax";

function Header({
  children,
  headerContent,
  beforeHeaderContent,
  afterHeaderContent,
  backgroundContent,
  backgroundImage,
  className,
  innerClassName,
  wide = false,
  grid = false,
  variant = "light",
  fullHeight = false,
  blur = false,
  parallax = false,
}) {
  const classes = [
    "header",
    "relative z-0 ",
    fullHeight && "min-h-screen-layout py-0 md:py-0",
    parallax && "parallax py-0 md:py-0",
    className,
  ];

  if (variant === "light") {
    classes.push("light bg-white text-black");
  }
  if (variant === "brand") {
    classes.push("brand bg-brand text-white");
  }
  if (variant === "yellow") {
    classes.push("yellow bg-yellow text-black");
  }
  if (variant === "gradient") {
    classes.push(
      "gradient bg-[radial-gradient(ellipse_at_bottom_left,_var(--tw-gradient-stops))] from-brandLight via-brand-900 to-brand"
    );
  }

  let theHeaderContent =
    headerContent ||
    ((children || beforeHeaderContent || afterHeaderContent) && (
      <>
        {beforeHeaderContent}
        <div
          className={classNames(
            ...[
              "container max-w-6xl mx-auto",
              parallax && "py-0 md:py-0",
              innerClassName,
            ]
          )}
        >
          {children}
        </div>
        {afterHeaderContent}
      </>
    ));

  if (backgroundImage) {
    const image = getImage(backgroundImage);
    theHeaderContent = (
      <>
        <Parallax speed={12} className={classNames("section__bg")}>
          <GatsbyImage alt={""} image={image} className={""} />
          {backgroundContent}
        </Parallax>
        {theHeaderContent && (
          <div
            className={classNames(
              "section__inner h-56 lg:h-64 xl:h-96 2xl:h-128 container px-6 max-w-6xl grid grid-cols-6 justify-center items-center",
              fullHeight && "min-h-screen-layout"
            )}
          >
            <Parallax speed={-1} className="w-full col-span-3 sm:col-span-2">
              {children}
            </Parallax>
          </div>
        )}
      </>
    );
  }

  return <header className={classNames(...classes)}>{theHeaderContent}</header>;
}

export function LightHeader(props) {
  return <Header variant={"light"} {...props} />;
}

export function BrandHeader(props) {
  return <Header variant={"brand"} {...props} />;
}

export function YellowHeader(props) {
  return <Header variant={"yellow"} {...props} />;
}

export function GradientHeader(props) {
  return <Header variant={"gradient"} {...props} />;
}

export default Header;
